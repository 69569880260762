<template>
  <div v-if="isLoading">
    <aom-skeleton-loader />
  </div>
  <div v-else>
    <b-card
      v-for="(program, index) in companyPrograms"
      :key="index"
      style="cursor: pointer"
    >
      <b-card-text>
        <a
          :href="`//${program.client.subdomain}.${AOM_MAIN_DOMAIN}/${program.path}`"
          target="_blank"
        >
          <h4 class="text-primary">{{ program.name }}</h4>
          <p>
            <b-badge
              v-for="(role, index) in displayRoles(program)"
              :key="index"
              pill
              variant="primary"
              class="ml-auto"
              style="margin-right: 2px"
            >
              {{ role }}
            </b-badge>
          </p>
          <p>
            Duration {{ formatDate(program.licence_start_date) }} -
            {{ formatDate(program.licence_end_date) }}
          </p>
        </a>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import { BCard, BCardText, BBadge } from "bootstrap-vue";
import { makeErrorToast } from "@/libs/utils";
import programsService from "@/services/programsService";
import { formatDateTimeFromIsoDateTime } from "@/libs/utils";
import AomSkeletonLoader from "@aom-core/AomSkeletonLoader.vue";
import { userRoles } from "@/models/userRoles";
import { AOM_MAIN_DOMAIN } from "@/constants/app";

export default {
  components: {
    BCard,
    BCardText,
    AomSkeletonLoader,
    BBadge
  },
  data() {
    return {
      isLoading: false,
      companyPrograms: [],
      AOM_MAIN_DOMAIN
    };
  },
  computed: {
    program() {
      return this.$store.getters["participants/program"];
    },
    mentorDisplay() {
      return this.$store.getters["participants/mentorDisplay"];
    },
    menteeDisplay() {
      return this.$store.getters["participants/menteeDisplay"];
    },
  },
  created() {
    this.loadItems();
  },

  methods: {
    async loadItems() {
      try {
        this.isLoading = true;
        const reponse = await programsService.getClientPrograms(
          this.program.client_id
        );
        this.companyPrograms = reponse.data.items;
      } catch (e) {
        this.$toast(
          makeErrorToast("Program list not loaded.")
        );
        this.$log.error(e);
      } finally {
        this.isLoading = false;
      }
    },
    formatDate(date) {
      return formatDateTimeFromIsoDateTime(date, true, 'DD-MM-YYYY');
    },
    displayRoles(program) {
      if (program.user_roles && program.user_roles.length) {
        if (this.program.id === program.id) {
          return program.user_roles
          .map(
            userRole => {
              if (userRole.role.id === userRoles.MENTOR && this.mentorDisplay) {
                return this.mentorDisplay;
              } else if (userRole.role.id === userRoles.MENTEE && this.menteeDisplay) {
                return this.menteeDisplay;
              }
              return userRole.role.name.charAt(0).toUpperCase() +
                userRole.role.name.slice(1);
            }
          );
        }
        return program.user_roles
          .map(
            userRole =>
              userRole.role.name.charAt(0).toUpperCase() +
              userRole.role.name.slice(1)
          );
      }
    },
  },
};
</script>
